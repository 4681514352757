import React, { useState, useEffect } from 'react';
import logo from './sunbase-logo.png'
import './App.css';
import { ArrowRightCircleIcon, SparklesIcon, ArrowPathIcon, BuildingLibraryIcon } from '@heroicons/react/20/solid'
import ReactMarkdown from 'react-markdown';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ChatWindow({ source = 'incentives', sample_questions, placeholder }) {
    const [answer, setAnswer] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    const [question, setQuestion] = useState(null);
    const [shouldCallForSources, setShouldCallForSources] = useState(null);
    const [sources, setSources] = useState([]);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const generateToken = () => {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        };

        setToken(generateToken());
    }, []);

    useEffect(() => {
        if (shouldCallForSources) {
            (async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND}/sources?id=${shouldCallForSources}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'token': token,
                        },
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch sources');
                    }
                    const data = await response.json();
                    setSources(data);
                } catch (error) {
                    console.error('Error fetching sources:', error);
                }
            })();
        }
    }, [shouldCallForSources]);

    
    const sendMessage = async (message) => {
        try {
            setShouldCallForSources(null)
            setAnswer('')
            setSources([])
            setQuestion(message)
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/start-stream`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token,
                },
                body: JSON.stringify({ messages: [{ role: 'user', content: message }] }),
            });

            const chatEventSource = new EventSource(
                `${process.env.REACT_APP_BACKEND}/chat-stream?token=${token}&src=${source}`
            );

            chatEventSource.onmessage = event => {
                const data = JSON.parse(event.data);
                if (typeof data.content === 'string') {
                    handleStreamedData(data.content, 'assistant');
                } else if (typeof data.content === 'boolean') {
                    chatEventSource.close();
                    setShouldCallForSources(data.id);
                } else {
                    console.log(typeof data.content)
                    console.error('Received non-string content:', data.content);
                }
            };

            chatEventSource.onerror = error => {
                console.error('EventSource failed:', error);
                chatEventSource.close();
            };
        } catch (error) {
            console.error(error);
        }
    };

    const handleStreamedData = (content, source) => {
        if (typeof content !== 'string') {
            console.error('Expected content to be a string, but got:', content);
            return;
        }
        setAnswer(prevAnswer => prevAnswer ? prevAnswer + content : content);
    };

    const handleInputChange = (event) => {
        setCurrentInput(event.target.value)
    };

    const handleInputKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await sendMessage(currentInput);
            setCurrentInput('');
        }
    };

    const goHome = () => {
        setAnswer(null);
        setQuestion(null);
        setCurrentInput('');
    }

    return (
        <>
            {question ?
                <div className='h-screen'>
                    <div className="flex justify-center items-center cursor-pointer" onClick={goHome}>
                        <img src={logo} alt="logo" className="h-10 w-10" />
                        <h1 className="text-gray-700 text-center ml-3 font-bold text-xl sm:text-2xl my-3">SunBase<span className='text-orange-500'>.ai</span></h1>
                    </div>

                    {/* Adjusted mx-4 for smaller screens */}
                    <div className="flex flex-col h-[91vh] justify-between mx-4 sm:mx-10 border border-gray-200 rounded-md p-3">
                        <>
                            <div className='flex items-center justify-between pt-3'>
                                <div className="text-left pl-4 ">
                                    {/* Adjusted text size for smaller screens */}
                                    <h1 className="text-gray-900 font-bold text-lg sm:text-2xl">{question}</h1>
                                </div>
                                <button onClick={() => sendMessage(question)} className="text-orange-500 flex items-center justify-center space-x-2 bg-white  border-2 border-300 p-2 rounded-lg mr-4 hover:bg-gray-100 text-xs sm:text-sm font-medium">
                                    <ArrowPathIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                                    <p>Regenerate</p>
                                </button>
                            </div>
                            <hr className="my-4 px-3 border-t-2" />
                            <span className="bg-white flex items-center justify-begin rounded-full pl-4  text-xs">
                                <SparklesIcon className="h-4 w-4 text-orange-400 mr-1.5" aria-hidden="true" />
                                <h2 className="text-orange-500 font-bold text-lg">Answer</h2>
                            </span>
                            <div className="h-4/5 pl-4 w-full flex flex-col justify-between overflow-auto">
                                <div>
                                    {!answer ? <Skeleton count={5} /> : null}
                                    <ReactMarkdown className="text-gray-900">{answer}</ReactMarkdown>
                                </div>
                                {sources.length > 0 && (
                                    <div>
                                        <span className='bg-white flex items-center justify-begin mt-4'>
                                            <BuildingLibraryIcon className="h-4 w-4 text-orange-400" aria-hidden="true" />
                                            <h3 className="text-orange-500 font-bold text-lg ml-1.5">Sources</h3>
                                        </span>

                                        <div className="flex flex-col md:flex-row gap-3">
                                            {sources.map((source, index) => (
                                                <div key={index} className="bg-gray-100 p-3 hover:bg-gray-300 flex-1 min-w-[15%] rounded-lg text-xs sm:text-sm text-gray-500 mt-2 ">
                                                    <a href={`${source.location}#page=${source.page_number}`} target="_blank" rel="noreferrer">
                                                        <p className='text-blue-400 underline'>{source.source}</p>
                                                        <p>{source.text.substring(0, 100)}...</p>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                        <div className={`w-full px-4 py-2`}>
                            <div className='w-full mt-2 flex rounded-md shadow-sm'>
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={currentInput}
                                        onChange={handleInputChange}
                                        onKeyDown={handleInputKeyDown}
                                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Ask another question..."
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 bg-white cursor-pointer"
                                    onClick={() => sendMessage(currentInput)}
                                >
                                    <ArrowRightCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="flex justify-center items-center h-screen">
                    <div className="w-full sm:w-1/2 flex -mt-16 flex-col justify-center items-center rounded-md">
                        <div className="flex justify-center items-center">
                            <img src={logo} alt="logo" className="h-10 w-10" />
                            <h1 className="text-gray-700 text-center ml-3 font-bold text-xl sm:text-2xl my-3">SunBase<span className='text-orange-500'>.ai</span></h1>
                        </div>
                        <div className='w-5/6 md:w-full mt-6 flex rounded-md shadow-sm'>
                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={currentInput}
                                    onChange={handleInputChange}
                                    onKeyDown={handleInputKeyDown}
                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder={placeholder}
                                />
                            </div>
                            <button
                                type="button"
                                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 bg-white cursor-pointer"
                                onClick={() => sendMessage(currentInput)}
                            >
                                <ArrowRightCircleIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="w-5/6 md:w-full md:flex-row flex-col items-center flex justify-center md:space-x-3">
                            {sample_questions.map((question, index) => (
                                <div key={index} className="mt-4 py-2 text-xs sm:text-sm px-4 bg-gray-300 text-gray-800 cursor-pointer hover:bg-gray-800 hover:text-gray-100 rounded-lg" onClick={() => sendMessage(question)}>
                                    {question}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ChatWindow;